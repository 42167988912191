* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.history-container {
  background-color: #f8f8ff;
  align-self: stretch;
}

.lightSmallText {
  color: #999;
  font-size: 0.9em;
}

.confetti-canvas {
  position: fixed;
  pointer-events: none;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
}

.big-buttons-container {
  display: flex;
  flex-direction: row;
}

.statsContainer {
  align-self: flex-start;
  padding: 30px;
  h3 {
    margin-bottom: 10px;
  }
  ul {
    list-style: none;
    li {
      margin-bottom: 10px;
    }
  }

  a {
    color: rgb(23, 43, 77);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.wrapper {
  display: flex;
  min-height: 100vh;
  max-width: 1280px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  background-color: white;

  .header {
    img {
      width: 100%;
    }
  }

  .teamPageSection {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    h1 {
      color: #e5446b;
      max-width: 90%;
      span {
        text-transform: uppercase;
      }
    }

    > span {
      color: #999;
      max-width: 90%;
    }

    .inputImageControl {
      margin: 20px;
    }

    .selectBtn {
      border: solid 1px lighten(#e5446b, 30%);
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
      &:active {
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
          rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      }
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 5px;

      .plusBtn {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        color: white;
        font-size: 1.6em;
        font-weight: bold;
        text-align: center;
        border-radius: 10px;

        svg {
          fill: #e5446b;;
        }
      }
    }
  }

  .gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-left: 10px;

    .onePhotoContainer {
      display: flex;
      flex-direction: column;

      .onePhoto {
        object-fit: contain;
        max-height: 250px;
        max-width: 100%;
        margin-right: 10px;
        margin-bottom: 10px;
        min-width: auto;
        min-height: auto;
        cursor: pointer;
      }

      button {
        font-size: .8em;
        position: absolute;
        margin-left: 3px;
        margin-top: 3px;
        padding: 0 3px;
        display: none;
      }

      &:hover {
        button {
          display: inline;
        }
      }
    }

    .sample-photo {
      position: relative;
      display: flex;

      img {
        cursor: default !important;
        // opacity: .5;
      }

      &:after {
        content: 'SAMPLE';
        color: #fff;
        position: absolute;
        font-weight: bold;
        bottom: 0;
        margin-bottom: 1em;
        margin-left: 1em;
      }
    }

    .placeholder {
      background-color: #eee;
      color: #999;
      width: 250px;
      height: 100%;
      min-height: 150px !important;
      font-size: large;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: default !important;
      border: dotted 3px #ccc;
    }
  }

  .selectedSection {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 23px 0;

    .uploadBtn,
    .clearSelectionBtn {
      border: none;
      outline: none;
      padding: 10px;
      cursor: pointer;
      border-radius: 5px;
      margin-right: 5px;
    }

    .uploadBtn {
      color: darkgreen;
      font-weight: bold;
    }

    .clearSelectionBtn {
      color: #555;
    }
  }

  .selectedImage {
    max-width: 80%;
    max-height: 400px;
    margin-top: 10px;
  }

  .celebrateButtonLeft,
  .celebrateButtonRight {
    border: none;
    outline: none;
    font-size: 1em;
    background-color: white;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    &:active {
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
  }
  .celebrateButtonRight {
    transform: rotate(270deg);
  }
}

.home-link {
  color: #e5446b;
}

.upload-nag, .home-link {
  font-size: 1.2em;
  margin-bottom: 20px;
  font-weight: 500;
}
